.county-code-select {
    .ant-input-group-addon {
        width: 90px;
        background-color: #fff;
    }

    .ant-input-group-addon:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 90px;
    }
}

.phone-input-wrapper {
    width: 100%;
    display: flex;

    .ant-input {
        border-radius: 0 8px 8px 0;
    }
}

.phone-select-wrapper {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 8px 0 0 8px;
    }
}
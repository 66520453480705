@import 'assets/scss/_variables.scss';

.language-link {
  float: left;
  margin-right: 5px;
}

.courses-toolbar-wrapper{
  display: flex;
  justify-content: space-between;
}

.courses-switch-filter{
  display: flex;
  align-items: center;
}

.curses-toolbar-wrapper-right{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: row-reverse;
  align-items: center;
}
@import 'assets/scss/_variables.scss';

.page-header {
  .ant-breadcrumb {
    margin: 0;
    padding: 16px 0;
    font-weight: 500;
    color: $text-color-03;
  }

  h1 {
    i {
      font-size: 16px;
      color: $text-color-01;
      padding-right: 10px;
    }

    font-size: 32px;
    margin-bottom: 24px;
    line-height: 1;
  }

  &.loading {
    visibility: hidden;
  }
}

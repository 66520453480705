.ant-picker-time-panel-column {
    overflow-y: auto;
}

.ant-picker-ranges .ant-picker-ok {
    margin-left: 0;

    .ant-btn-sm { padding: 0 8px; }
}

.ant-picker-time-panel-column {
    width: 135px;
}

/* Hides the minute column, minute column is needed so that the input shows mintues too,
for example, 21:00 instead of just 21 */
.ant-picker-time-panel-column:nth-of-type(2) { display: none; }

.ant-picker-time-panel-cell-inner::after { content: ':00' }

.when-to-send {
    margin: 0;
}

.popover-table{
    .ant-table-container{
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .ant-table-pagination.ant-pagination {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.campaign-progress-checkbox{
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
         margin-left: 0px;
    }
}
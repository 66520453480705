.custom-filter-tags{
    box-sizing: border-box;
    height: 24px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    background-color: #FAFAFA;
    color: #595959;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    display:inline-block;
    line-height: 17px;
    margin-bottom: 3px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 8px;
}

.filter-by-label{
    margin-top: 5px;
    color: #262626;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
}

.clear-all-button {
    outline: 0;
    white-space: nowrap;
}

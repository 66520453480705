.ant-select.default-select {
    .ant-select-selector {
      border-radius: 8px;
    }
  }
  .ant-form-item-label{
    white-space: normal;
    overflow: visible;
  }

.custom-language-tags{
    box-sizing: border-box;
    height: 24px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    background-color: #FAFAFA;
    color: #595959;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    display:inline-block;
    line-height: 17px;
    margin-bottom: 3px;
    margin-top: 3px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 8px;
}

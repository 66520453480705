$badgeSize: 15px;
$lineHeight: 17px;

.chart {

    &-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &-title {
        margin: 0 0 25px;

        color: black;
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 1;
        text-align: left;

        &.small-margin { margin-bottom: 20px; }
    }

    &-download-button {
        margin:  -4px -6px 0 0;
        padding: 8px 10px;
        border: 0;

        color: #8C8C8C;
        font-size: 16px;
        line-height: 16px;

        background: none;
        cursor: pointer;
        transition: color .2s;

        &:hover { color: #000000; }

        i { display: block; margin: 0; }
    }

    &-legend {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    
        &-item {
            display: flex;
            margin: 10px 7px 0;
        }
    
        &-badge {
            width: $badgeSize; height: $badgeSize;
            margin-top: ($lineHeight - $badgeSize) / 2;
            margin-right: 5px;
            border-radius: 15px;
        }
    
        &-text {
            color: black;
            font-size: .9rem;
            text-align: left;
            line-height: $lineHeight;
    
            p { margin: 0; }
        }
    
        &-label { font-weight: 500; }
    }

    &-footer {
        margin: 15px 0 0;
        text-align: left;
        color: #8E8E8E;
    }
}

.doughnut {
    &-wrapper {
        position: relative;

        canvas {
            position: relative;
            z-index: 2;
        }

        p {
            margin: 0;
            color: black;
            line-height: 1.1;
            user-select: none;
        }
    }

    &-corner {
        position: absolute;
        top: 0; left: 0;

        text-align: left;
    }

    &-inner {
        position: absolute;
        top: 0; bottom: 0; left: 50%;
        transform: translateX(-50%);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 10%;

        pointer-events: none;

        p { text-align: center; }
    }

    &-text {
        font-size: 1.3vw;

        @media(min-width: 1600px) { font-size: 1.3rem; }

        &.small {
            font-size: 1.1vw;

            @media(min-width: 1600px) { font-size: 1.1rem; }
        }
    }

    &-number {
        font-size: 2vw;

        @media(min-width: 1600px) { font-size: 2rem; }
    }
}
@import 'assets/scss/_variables.scss';

.student-campaign-tag-process {
    margin-top: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    height: 22px;
    border: 1px solid #A1D4FB;
    border-radius: 8px;
    background-color: #E9F7FE;
}

.student-campaign-tag-new {
    margin-top: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    height: 22px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    background-color: #F5F5F5 !important;
    color: rgba(0,0,0,0.65);
    font-family: 'Gilroy';
    font-size: 12px;
    font-weight: 600;
}

.student-campaign-tag-unfinished {
    margin-top: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    height: 22px;
    border: 1px solid #EFA7A1;
    border-radius: 8px;
    background-color: #FCF1F0;
    font-family: 'Gilroy';
    font-size: 12px;
    font-weight: 600;
}

.student-campaign-tag-finished {
    margin-top: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    height: 22px;
    border: 1px solid #C3E999;
    border-radius: 8px;
    background-color: #F8FEEF;
    font-family: 'Gilroy';
    font-size: 12px;
    font-weight: 600;
}

.student-campaign-tag-canceled {
    margin-top: 8px;
    margin-left: 10px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
}

.finished-unfinished {
    margin-top: 8px;
    margin-left: 10px;
    color: #262626;
    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.student-campaign-tag-not-started {
    margin-top: 8px;
    margin-left: 10px;
    box-sizing: border-box;
    height: 23px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #FAE59A;
    border-radius: 8px;
}

.student-download-certificate {
    color: #19A9CE;
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.division-buttons {
    .student-start {
        box-sizing: border-box;
        height: 32px;
        width: 159px;
        border: 2px solid $theme-color;
        border-radius: $button-border-radius;
        background-color: $button-color-primary;
        color: $link-color;
        font-size: 14px;
        font-weight: bold;
        &:hover {
            background-color: $button-color-primary;
            border: 2px solid $theme-color;
            color: $link-color;
        }
    }

    .student-continue {
        border-radius: $button-border-radius;
        height: 32px;
        width: 159px;
        background-color: $theme-color;
        font-size: 14px;
        font-weight: bold;
        color: $button-color-primary;
    }

    .view-content {
        box-sizing: border-box;
        height: 32px;
        width: 159px;
        border: 2px solid $theme-color;
        border-radius: $button-border-radius;
        background-color: $button-color-primary;
        color: $link-color;
        font-size: 14px;
        font-weight: bold;
        &:hover {
            background-color: $button-color-primary;
            border: 2px solid $theme-color;
            color: $link-color;
        }
    }
}

.activities-alert{
    .ant-alert-message{
        font-size: 24px;
        font-weight: 500;

    }
    .ant-alert-description{
        font-size: 20px;
        font-weight: 400;
    }
}

.student-campaign-tag-default {
    margin-left: 10px;
}
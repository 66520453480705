.ant-row {
    .chart-card{
        box-sizing: border-box;
        position: relative;
        margin-bottom: 16px;
        
        .ant-card-body{
            height: auto;
            min-height: 400px;
            
            .ant-spin-container{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        &.ant-card{
            height: auto;
        }

        &.adaptive{
            height: auto;
            max-height: none;
            
        }
    }
}
 
.ant-radio-group{
  .ant-radio-checked .ant-radio-inner {
    border-color: $theme-color;
  }
  .ant-radio-inner::after {
    background-color: $theme-color;
  }
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: $theme-color;
  }
}
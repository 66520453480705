@import 'assets/scss/_variables.scss';

.spinner-with-white-background {
  background: #ffffff;

  .ant-spin-blur { opacity: 0; }
}

.ant-form-item-has-error .ant-transfer-list {
  border-color: #ff4d4f !important;
}

.ant-input:read-only {
  color: rgba(0, 0, 0, .5);
  background-color: rgba(0, 0, 0, .05);
}

.date-incoming { color: orange; }
.date-passed { color: #ff4d4f; }

.ant-btn-link {
  color: $theme-color;
  transition: color .3s;

  &:hover { color: $theme-color; }
}

@import 'assets/scss/_variables.scss';

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: $content-menu-background-color;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.form-group-separator {
  width: 100%;
  margin-left: 0%;
  border: 0;
  border-bottom: 1px solid #E8E8E8;
}

.content-layout {
  .content-sidebar {
    border: 0;
    border-right: 0px solid #f0f0f0;
    min-height: 100vh;

    .ant-menu-vertical, .ant-menu-vertical-left {
      border-right: 0;
    }

    .ant-menu {
      background-color: $content-menu-background-color;
      max-width: 100% !important;

      &-sub {
        background-color: $content-menu-background-color !important;
      }

      &-item-selected {
        background-color: $menu-active-bg-color !important;
        color: $content-menu-active-text-color !important;
        font-weight: 600;
      }

      &-submenu-title,
      &-item,
      &-item a {
        color: $content-menu-text-color;

        &:hover {
          color: $content-menu-text-color;
          font-weight: 600;
          .ant-menu-submenu-arrow::before,
          .ant-menu-submenu-arrow::after {
            background-color: $content-menu-text-color !important;
          }
        }
      }

      &-item.ant-menu-item-active a,
      &-item.ant-menu-item-selected a,
      &-item.ant-menu-item-selected a span {
        color: $content-menu-active-text-color !important;
      }

      &-submenu-arrow::before,
      &-submenu-arrow::after {
        background-color: $content-menu-active-text-color !important;
        font-weight: 600;

      }
    }
  }
}

.content-card-container {
  .ant-card-bordered .ant-card-cover {
    margin-right: 0;
    margin-left: 0;
  }

  .ant-spin-nested-loading {
    height: 100%;
  }

  .content-card {
    width: 320px;
    height: 300px;
    display: flex;
    flex-direction: column;
    border: 0;
    font-size: 16px;

    .content-image {
      width: 320px;
      min-height: 180px;
      height: 180px;
      object-fit: fill;
      border-radius: 8px 8px 0 0 !important;
    }

    .ant-card-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      border: 0;
    }

    .content-title {
      margin: 5px 15px 5px 15px;
      color: #595959;
      font-weight: bold;
      height: 60px;
      max-height: 60px;
    }

    .form-group-separator {
      width: 92%;
      margin-left: 4%;
      border: 0;
      border-bottom: 1px solid #E8E8E8;
    }

    .content-settings {
      margin-left: 10px;
      margin-bottom: 15px;
      .fal {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
    .content-settings-flags {
      margin-right: 12px;

    }
  }
}

.request-content-page-header {
  margin-top: 64px;
  background: 0;
  height: 100px;
  line-height: 50px;
  margin-bottom: 40px;
  .request-content-left-page-header {
    font-weight: 500;
    font-size: 32px;
  }

  .ant-form-item {
    margin-bottom: 5px;
  }
}


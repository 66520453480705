@import 'assets/scss/_variables.scss';

.fa-ellipsis-v {
  padding:$padding-for-fa-ellipses-v;
}

.selectedUsers{
  margin-top: 5px;
}


.users-list-footer{
  .fal{
    padding-right: 5px;
  }
}
.radio-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15%;
    margin-bottom: 2%;
}

.form-alert{
    .ant-form-item-label > label::after {
        content: none;
    }
}

.sms-text-area {
    height: 160px;

     textarea.ant-input{
        height: 160px;
        resize: none;
    }
}

.tab-row {
    .ant-tabs-nav{
        margin-left: 33.5%;
        margin-right: 16.5%;
    }
}
@import 'assets/scss/_variables.scss';

.language-link {
  float: left;
  margin-right: 5px;
}

.DRAFT {
  box-sizing: border-box;
  height: 23px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #FAE59A;
  border-radius: 8px;
}

.ONGOING {
  box-sizing: border-box;
  height: 23px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #A1D4FB;
  border-radius: 8px;
}

.FINISHED {
  height: 22px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #C3E999;
  border-radius: 8px;
}

.launch-button {
  box-sizing: border-box;
  height: 32px;
  border: 2px solid #19A9CE;
  color: #19A9CE;
  border-radius: 8px;
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

.create_elearning_campaign {
  .ant-col-6 {
    max-width: 60%;
  }
}
.video-instruction-iframe {
  width: 100%;
  height: 720px;
}

.video-instruction-modal {
  max-width: 1280px;
  width: 80% !important;
  .ant-modal-body {
    margin-top: 20px;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ant-tabs-tab { 
    border-radius: $tab-border-radius !important;
    &:hover {
        color: $theme-color;
    }
    .ant-tabs-tab-btn{
        &:hover {
            color: $theme-color;
        }
    }
}
.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: $theme-color !important;
}
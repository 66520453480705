@import 'assets/scss/_variables.scss';

.primary-button, .primary-button:hover, .primary-button:focus {
  border-radius: 8px;
  background-color: $button-bg-primary;
  color: white;
  padding: 8px 12px;
  border: 0;
  line-height: 1;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.outlined-button, .outlined-button:hover, .outlined-button:focus {
  border-radius: 8px;
  color: $text-color-02;
  padding: 8px 12px;
  line-height: 12px;
  background-color: white;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-sm, .outlined-button:hover, .outlined-button:focus {
  padding: 6px 8px;
}

.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 12px;
}

.ant-table-wrapper {
    margin-top: 16px;
}

.upload-scorm-icon {
    font-size: 16px;
    margin-right: 5px;
}

.pass-rate-input {

    display: flex;

    margin: 0 16px;

    .ant-form-item-label>label::after {
        content: '';
    }
}

.pass-rate-label {
    display: flex;
    justify-content: flex-end;
    color: black;

}

.pass-rate-label::after {
    content: ':';
    margin: 0 8px 0 2px
}

.warning-wrapper{
    padding: 10px 0;
}

.warning-container {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;

    .fa-solid{
        padding-right: 10px;
    }
}

.warning-wrapper{
    padding: 10px 0;
}

.warning-container {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;

    .fa-solid{
        padding-right: 10px;
    }
}

.activity-title{
    font-weight: 600;
}

.ant-btn {
    padding: $button-padding;
    border-radius: $button-border-radius;
    line-height: 1;

    color: $button-color;

    &:hover {
        color: $button-color-hover;
        border-color: $button-border-color-hover;
    }

    &:focus {
        color: $button-color-focus;
        border-color: $button-border-color-focus;
    }

    &.ant-btn-lg {
        height: $button-height-big;
        padding: $button-padding-big;
    }

    &.ant-btn-link {
        color: $link-color;

        &:hover,
        &:focus { border-color: transparent; }

        &:hover { color: $link-color-hover; }
        &:focus { color: $link-color-focus; }
    }

    &.ant-btn-primary {
        border-color: $button-border-color-primary;

        color: $button-color-primary;
        background-color: $button-bg-primary;

        &:hover { background-color: $button-bg-primary-hover; }
        &:focus { background-color: $button-bg-primary-focus; }

        &[disabled] {
            color: $button-color-primary-disabled;
            border-color: $button-border-color-primary-disabled;
            background-color: $button-bg-primary-disabled;

            &:hover,
            &:focus { color: $button-color-primary-disabled; }
        }
    }

    &.ant-btn-dangerous {
        color: $button-color-danger;

        &:hover,
        &:focus { border-color: $button-color-danger; }

        &[disabled] {
            color: $button-color-primary-disabled;
            border-color: $button-border-color-primary-disabled;
            background-color: $button-bg-primary-disabled;

            &:hover,
            &:focus { color: $button-color-primary-disabled; }
        }
    }

    // Student buttons
    &.ant-btn-student {
        border-color: $button-border-color-student;
        border-width: $button-border-width-student;

        color: $button-color-student;
        font-weight: $button-font-weight-student;

        &.ant-btn-primary { color: $button-color-student-primary; }
    }

    & > .fal + span,
    & > span + .fal { margin-left: 8px; }
}
@import 'assets/scss/_variables.scss';

.fal{
    margin-left: 2px;
}

.ant-modal-title{
  font-weight: 600;
}

.noColon .ant-form-item-label > label::after {
  content: none;
}
.itemStyle {
  display: inline-block;
}

.background-and-style {
  width: 130px;
  height: 100px;
  display: inline-block;
  margin: 8px;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  background-color: #FAFAFA;
  cursor: pointer;
}

.background-and-style-active {
  width: 130px;
  height: 100px;
  display: inline-block;
  margin: 8px;
  border: 1px solid $theme-color;
  border-radius: 8px;
  background-color: #FAFAFA;
  cursor: pointer;
}

.campaign-email-template-modal .page-layout {
  margin: 0;
  .page-content {
    padding: 0;
  }
}

.preview-button {
  border: 2px solid $theme-color;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: $theme-color;
  &:hover {
    border-color: $theme-color;
    color: $theme-color;
  }
  &:focus {
    border-color: $theme-color;
    color: $theme-color;
  }
  i.header-item {
    color: $theme-color;
    margin-right: 4px;
  }
}
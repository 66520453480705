@import '../../assets/scss/variables';

$responsive-width: 992px;
$grid-gap: 1rem;
$desktop-button-width: 160px;

.card > :global(.ant-card-body) {
    padding: 1rem;

    @media (min-width: $responsive-width) { padding: 1.5rem; }
}

.grid {
    display: grid;
    grid-template-rows:  repeat(3, auto);
    grid-template-columns:  repeat(2, 1fr);
    grid-template-areas:
    'Badge Progress'
    'Information Information'
    'Button Button';
    gap: $grid-gap;

    @media (min-width: $responsive-width) {
        grid-template-columns: repeat(2, auto) 1fr 160px;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
            'Badge Information Progress Button'
            'Badge Information Progress Button';
        gap: $grid-gap * 1.5;
    }

    &WithCertificate {
        grid-template-areas:
            'Badge Progress'
            'Information Information'
            'Certificate Certificate'
            'Button Button';

        @media (min-width: $responsive-width) {
            grid-template-areas:
            'Badge Information Progress Button'
            'Badge Information Certificate Button';
        }
    }

    &WithoutButton > .informationContainer {
        margin-bottom: -$grid-gap;

        @media (min-width: $responsive-width) { margin-bottom: 0; }
    }
}

.badge {
    position: relative;
    display: inline-block;

    &Inner {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: 1;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 11px;
        border-radius: 50%;

        background-color: $menu-bg-color;
    }

    &Icon {
        margin: 0 !important;
        color: white;
        font-size: 2.25rem;
    }

    &Container {
        grid-area: Badge;

        @media (min-width: $responsive-width) {
            display: flex;
            align-items: center;
        }
    }
}

.progress {
    margin: 0;

    color: black;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    text-align: right;
    white-space: nowrap;

    @media (max-width: 300px) {
        font-size: 0.75rem;
    }

    &Container {
        grid-area: Progress;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (min-width: $responsive-width) { justify-content: center; }
    }
}

.information {

    &Title {
        margin: 0;

        color: black;
        font-size: 1.5rem;
        @media (max-width: 300px) {
            font-size: 1rem;
        }

        font-weight: 600;
        line-height: 1;

        + span { margin: 0 0.75em 0 0.75em; }

        &Wrapper {
            display: flex;
            align-items: center;
        }
    }

    &List {
        margin-top: 1rem;

        @media (min-width: $responsive-width) {
            display: flex;
            align-items: center;
        }
    }

    &Item {
        display: flex;
        align-items: center;

        margin: 0;

        color: black;
        font-size: 0.9rem;
        font-weight: 600;
        line-height: 1;

        &:not(:last-of-type) {
            margin-bottom: 0.75em;

            @media (min-width: $responsive-width) { margin: 0 1em 0 0; }
        }

        > i {
            margin: 0 0.5em 0 0;
            color: $theme-color;
            font-size: 1rem;
        }

        &Missed,
        &Missed > i { color: red; }
    }

    &Container {
        grid-area: Information;

        @media (min-width: $responsive-width) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.certificate {

    &Container {
        grid-area: Certificate;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media (min-width: $responsive-width) { justify-content: center; }
    }

    &Title {
        margin: 0;
        white-space: nowrap;
    }

    &Icon { margin-right: 6px; }
}

.button {
    display: block;
    width: 100%;

    &Container {
        grid-area: Button;

        @media (min-width: $responsive-width) {
            display: flex;
            align-items: center;
            max-width: $desktop-button-width;

            > a { flex-grow: 1; }
        }
    }
}

.signature {
    display: block;
    width: 100%;

    &Container {
        grid-area: AntDButton;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (min-width: $responsive-width) { justify-content: center; }
    }
}

.instructions-layout {
  .form-group-separator {
    width: 96%;
    margin-left: 24px;
    margin-right: 24px;
    border: 0;
    border-bottom: 1px solid #E8E8E8;
  }

  .instructions-header {
    background-color: #f0f2f5;
    margin-top: 64px;
    height: 100%;
    font-size: 32px;
    font-weight: bold;
    color: black;
  }
  .ant-layout-header {
    padding: 0 24px;
  }
}

.video-instructions-content {
  .ant-tabs-content-holder{
    margin-bottom: 20px;
  }
  .ant-tabs-nav-wrap {
    margin-left: 24px;
  }
}

.instruction-filter-select {
  width: 240px;
  margin-right: 8px;
  margin-bottom: 0;
}

.instructions-content {
  margin-right: 35px;
  margin-left: 35px;
}

.instructions-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px 30px 15px;

  .ant-card-bordered .ant-card-cover {
    margin-right: 0;
    margin-left: 0;
  }

  .instructions-card {
    margin: 8px;
    width: 320px;
    height: 300px;
    border: 0;
    font-size: 16px;

    .instructions-card-image {
      width: 320px;
      min-height: 180px;
      height: 180px;
      object-fit: fill;
      border-radius: 8px 8px 0 0 !important;
    }

    .ant-card-body {
      padding: 0;
      border: 0;
    }
  }

  .instruction-settings {
    height: 120px;

    .form-group-separator {
      width: 96%;
      margin-left: 2%;
    }

    .instruction-title {
      display: inline-block;
      margin-left: 15px;
      margin-top: 5px;
      margin-right: 5px;
      color: #595959;
      font-weight: bold;
      height: 60px;
      max-height: 60px;
    }

    .instruction-info {
      margin-left: 10px;
      margin-bottom: 15px;
      .fal {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

.support-content {
  margin-left: 24px;
  margin-right: 24px;

  .support-title {
    font-weight: bold;
  }

  .support-info-title {
    font-weight: bold;
  }

  .support-info {
  }
}

.terms-conditions-content {
  margin-left: 24px;
  margin-right: 15px;
}

.terms-conditions-button {
  margin-right: 15px;

  .terms-edit-button{
    font-weight: bold;
    width: 50px;
  }
}

@import 'assets/scss/_variables.scss';
.add-user-select {
    margin-bottom: 0;
}


.fal{
    margin-left: 2px;
}

.ant-modal-title{
  font-weight: 600;
}
div.campaign-footer{
  display: inline-block;
}

.DRAFT {
  box-sizing: border-box;
  height: 23px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #FAE59A;
  border-radius: 8px;
}

.pageHeaderStyle .page-header {
  display: inline-block;
}

.noColon .ant-form-item-label > label::after {
  content: none;
}

.save-button {
  border: 2px solid $theme-color;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: $theme-color;
  &:hover {
    border-color: $theme-color;
    color: $theme-color;
  }
  i.header-item {
    color: $theme-color;
    margin-right: 4px;
  }
}

.steps-positions{
  display: flex;
  justify-content: space-between;
}
@import '../../assets/scss/variables';

.ant-input-affix-wrapper {
    border-radius: $input-border-radius;
    color: $icon-input-color;

    &:hover { border-color: $input-border-hover; }
    &:focus { border-color: $input-border-focus; }

    &:focus,
    &-focused { box-shadow: $input-box-shadow-focus; }
}

.ant-picker-cell {
    &-selected &-inner { background-color: $button-bg-primary-hover !important; }

    &-inner {user-select: none; }
    // ::before added by antd was causing some weird behaviour
    &::before { content: none; }
}


.day-picker {

    &-panel {
        display: flex;
        flex-direction: column;
        width: 280px;

        color: rgba(0, 0, 0, .85);
        text-align: center;
    }

    &-header {
        padding: 0 8px;
        border-bottom: 1px solid #f0f0f0;

        color: rgba(0, 0, 0, .85);
        font-weight: 500;
        line-height: 40px;
    }

    &-body { padding: 8px 12px; }

    &-content {
        width: 252px;
        border-collapse: collapse;
        table-layout: fixed;
    }
}
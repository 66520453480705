@import 'assets/scss/_variables.scss';

.session-switch-item {
  display: inline-flex;

  .role-switch {
    border: 1px solid #E8E8E8;
    align-self: center;
    padding: 8px 10px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    height: 32px;
    box-shadow: 0 2px 0 0 rgba(0,0,0,0.05);
    cursor: pointer;
    margin-right: 8px;

    span {
      padding: 0 5px;
      min-width: 32px;
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }

    i.with-accent {
      color: $link-color;
    }

    .display-role-name {
      @media (max-width: 1095px) {
        display: none;
      }
    }

    .display-language-name{
      @media (max-width: 1095px) {
        display: none;
      }
    }

    .display-organization-name{
      @media (max-width: 700px) {
        display: none;
      }
    }
  }
}

.session-switch-popover {
  padding-top: 0;

  .ant-popover-arrow {
    display: none;
  }
  
  .no-results-item {
    padding: 5px;
  }

  .select-item-seperator {
    font-weight: 700;
    font-size: 0.75rem;
    margin-bottom: 0;
    padding-left: 5px;
  }

  .select-item {
    padding: 5px;
    cursor: pointer;
  
    &:hover {
      background: #FAFAFA;
    }
  }
}

@import 'assets/scss/_variables.scss';

.page-footer {
  position: fixed;
  bottom: 0; left: 0;
  z-index: 2;
  width: 100%; height: $footer-height;

  display: flex;
  align-items: center;

  padding-left: calc(#{$sidebar-width} + 16px);
  padding-right: 16px;
  border-top: 1px solid #E8E8E8;
  box-shadow: 0 1px 4px 0 rgba(63, 69, 76, 0.08);

  background: white;

  &.sidebar-collapsed { padding-left: calc(#{$collapsed-sidebar-width} + 16px); }
}

.page-footer-size {
  @media (max-width: 1300px) {
    height: $footer-height * 1.5;
  }
}

.ant-card { border-radius: $card-border-radius; }

.ant-row .ant-card {
    height: 100%;

    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;

        .ant-spin-nested-loading {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .ant-spin-container { flex: 1; }
    }
}
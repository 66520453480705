.ant-steps {
  width: 85%;
  display: inline-block;
  vertical-align: top;
  .ant-steps-item-process .ant-steps-item-icon {
    background: $theme-color;
    border: none;
  }
  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title::after {
    background: #d9d9d9;
  }
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: rgba(0, 0, 0, 0.25);
  .ant-steps-icon{
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0;
}
.phishing-campaign-tags{
    .DRAFT {
        box-sizing: border-box;
        height: 23px;
        font-size: 12px;
        font-weight: 600;
        border: 1px solid #FAE59A;
        border-radius: 8px;
        text-align: center;
        padding-bottom: 20px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
      }
      
      .ONGOING {
        box-sizing: border-box;
        height: 23px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        border: 1px solid #A1D4FB;
        border-radius: 8px;
        padding-bottom: 20px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
      }
      
      .FINISHED {
          height: 20px;
          margin-bottom: 1px;
          background-color: #F8FEEF;
          font-family: Gilroy;
          font-size: 12px;
          padding-bottom: 20px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
      }

      .Inactive{
        color: #F5222D;
        font-family: Gilroy;
        width: 65px;
        font-size: 12px;
        font-weight: 600;
        box-sizing: border-box;
        border: 1px solid #EFA7A1;
        border-radius: 8px;
        background-color: #FCF1F0;
      }
}

.status-switch{
  margin-bottom: 0;
}
@import "assets/scss/_variables.scss";

.video-instruction-create-modal {
  width: 40% !important;
}

.video-length-input {
  color: rgba(0, 0, 0, 0.65) !important;
  background-color: transparent !important;
  cursor: default !important;
}

.instructions-tooltip {
  .ant-tooltip-inner{
    width: 300px;
  }
}

.default-input-field {
    padding: 0 12px;
    border-radius: 8px;
    height: 32px;
    .ant-input-number-input {
      margin-left: -10px;
      width: 100%;
      height: 30px;
      padding: 0 11px;
      text-align: left;
      background-color: transparent;
      border: 0;
      border-radius: 2px;
      outline: 0;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
      -moz-appearance: textfield !important;
    }
  }
  
@import 'assets/scss/_variables.scss';

.selected-campaign-thumbnail {
  display: flex;
  background-color: $base-color-active;
  border-radius: 8px;
  font-size: 40px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}

.campaign-header-text {
  text-align: left;
  color: #000;
  margin-bottom: 32px;

  .text-30 {
    font-weight: 700;
    font-size: 30px;
  }

  .text-20 {
    font-weight: 700;
    font-size: 20px;
  }

  .text-18 {
    font-weight: 700;
    font-size: 18px;
  }
}

.required-form-item-container{
  .required-form-item{
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;

  }

  .ant-form-item-label > label.ant-form-item-required::before {
    content: '';
  }
}

.electronic-journal-container{
  margin-bottom: 5%;
}
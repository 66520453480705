@import 'assets/scss/_variables.scss';

.spinner-with-white-background {
  background: #fff;

  .ant-spin-blur {
    opacity: 0;
  }
}

.form-group-seperator {
  margin-left: -16px;
  margin-right: -16px;
  border: 0;
  border-bottom: 1px solid $base-color-separator;
}

.form-group-header {
  color: #000;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.ant-picker{
  border-radius: 8px;
  width: 100%;
  height: 32px;
}

.checkbox-color{
    .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $theme-color;
}
  .ant-checkbox-checked .ant-checkbox-inner{
    border-color:$theme-color;
    background-color:$theme-color;
  }
}

.agreement-status{
  text-align: left;
  color: #000;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  height: 32px;

  .inactive-status{
    font-size: 16px;
    font-weight: bold;
    color: red;
    padding-right: 5px;
  }
  .active-status{
    font-size: 16px;
    font-weight: bold;
    color: green;
    padding-right: 5px;
  }
}

.end-date-expired{
  .ant-picker-input > input{
    color:red;
  }
}

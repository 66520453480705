@import 'assets/scss/_variables.scss';

.ant-picker {
  border-radius: 8px;
  width: 100%;
  height: 32px;

  i.fa-calendar-alt {
    color: $text-color-03;
  }
}

.file-properties-container {
    background-color: #F0F2F5;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .file-title{
        padding: 0 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
@import '../../assets/scss/variables';

.color-picker {
    display: flex;
    align-items: center;

    > .ant-form-item { margin: 0; }

    &-trigger {
        display: flex;
        align-items: center;

        height: $input-height;

        margin-left: 6px;
        padding: 0 6px;
        border: 1px solid rgb(217, 217, 217);
        border-radius: $input-border-radius;
        outline: 0;

        background-color: white;

        transition: all 0.3s;

        &:hover,
        &:focus,
        &.ant-popover-open { border-color: $theme-color; }

        &:focus,
        &.ant-popover-open { box-shadow: 0 0 0 2px rgba($theme-color, 0.2); }
    }

    &-preview {
        width: $input-height * 0.55 ; height: $input-height * 0.55;
        margin-right: 4px;
        border: 1px solid rgb(217, 217, 217);
        border-radius: 3px;
    }

    &-icon {
        color: gray;
        font-size: 9px;
    }
}

.chrome-picker {
    font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
@import 'assets/scss/_variables.scss';

.image-wrapper {
  .pdf-image {
    display: block;
    margin: auto;
    border: none;
  }
}

.pdf-progress-bar {
  display: flex;
  justify-content: space-around;

  .ant-progress-line {
    width: 65%;

    .ant-progress-bg {
      background-color: $base-color-active;
    }
  }
}

.pdf-material-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-sidebar {
    z-index: 3;
    margin-top: $header-height;

    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;

        background-color: $menu-bg-color;
        transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-menu {
        background-color: transparent;

        &-sub { background-color: $menu-submenu-bg-color !important; }
        &-item-selected { background-color: $menu-active-bg-color !important; }

        &-submenu-title,
        &-item,
        &-item a {
            color: $menu-text-color;

            &:hover {
                color: $menu-active-text-color;

                .ant-menu-submenu-arrow::before,
                .ant-menu-submenu-arrow::after { background-color: $menu-active-text-color !important; }
            }
        }

        &-item.ant-menu-item-active a,
        &-item.ant-menu-item-selected a,
        &-item.ant-menu-item-selected a span { color: $menu-active-text-color !important; }

        &-submenu-arrow::before,
        &-submenu-arrow::after { background-color: $menu-text-color !important; }
    }

    .menu-header {
        position: fixed;
        top: 0;
        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;

        width: $sidebar-width; height: $header-height;

        margin-bottom: 15px;
        background: white;

        &.collapsed { width: $collapsed-sidebar-width; }

        i {
            font-size: 15px;
            cursor: pointer;

            &.expanded {
                flex: 1;
                margin-right: 10px;
            }
        }

        img {
            height: 100%;
            max-width: 100%; max-height: $header-height;
            padding: 15px 30px 15px 15px;
        }
    }

    .menu-version {
        margin-top: auto;
        padding: .5em;
        color: $menu-active-text-color;
        font-size: .85rem;
        text-align: center;
    }
}
@import '../../../assets/scss/variables';

.file {
    background-color: #fff;
    border-radius: 8px;

    &RowLabel {
        padding: 10px;
        font-weight: 600;

        &Description {
            font-weight: 600;
            padding: 10px 10px 10px 22px;
        }
    }

    &Content {
        padding: 10px;

        &Icon {
            padding-right: 10px;
            color: $theme-color;
        }

        &Title {
            padding: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &Action {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        height: 100%;

        padding: 10px;

        &Download {
            text-decoration: underline;
            color: #19A9CE;
            cursor: pointer;
        }

        &Icon {
            color: #9D9D9D !important;
            cursor: pointer;

        }
    }

    &Row:not(:last-child) {
        border-bottom: 1px solid #E0E0E0;
    }

    &Details {
        padding: 10px;
    }

    &Row {
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
        align-items: center;

        &Element {
            width: 25%;
        }

        &Element:last-child {
            width: 10%;

            @media (max-width: 880px) {
                width: 100%;
            }
        }

        &DetailsElement {
            width: 10%;
        }
    }

    @media (max-width: 880px) {

        &Content {
            padding: 10px;

            &Title {
                width: 300px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                padding: 10px;
            }
        }

        &Row {
            display: grid;
            justify-content: normal;

            &Element {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 5px;
                align-items: center
            }

            &Element:not(:last-child) {
                border-bottom: 1px solid #E0E0E0;
            }

            &DetailsElement {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 5px;
                align-items: center
            }
        }

        &Row:not(:last-child) {
            border-bottom: 2px solid #E0E0E0;
        }
    }

    @media (max-width: 770px) {
        &ContentTitle {
            width: 200px;
        }
    }

    @media (max-width: 670px) {
        &ContentTitle {
            width: 180px;
        }
    }

    @media (max-width: 570px) {
        &ContentTitle {
            width: 100px;
        }
    }

    @media (max-width: 500px) {
        &ContentTitle {
            width: 200px;
        }
    }

    @media (max-width: 450px) {

        &Content {
            padding: 10px;

            &Title {
                width: 180px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                padding: 10px;
            }
        }

        &Row {
            display: grid;
            justify-content: normal;

            &Element {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                padding: 5px;
            }


            &Element:not(:last-child) {
                border-bottom: 1px solid #E0E0E0;

            }
        }

        &Row:not(:last-child) {
            border-bottom: 2px solid #E0E0E0;
        }
    }
}

@media (min-width: 880px) {
    .fileRow:not(:first-child) {
        .fileRowLabel {
            display: none;
        }

        .fileRowLabelDescription {
            display: none;
        }
    }
}
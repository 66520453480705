@import 'assets/scss/_variables.scss';

.help-button-login{
  $theme-color-global: var(--themeColorGlobal, #19A9CE);
  width: 32px;
  height: 32px;
  background-color: $theme-color-global;
  .icon-style {
    color: #ffffff;
  }
}

.help-button-login:hover{
  $theme-color-global: var(--themeColorGlobal, #19A9CE);
  background-color: $theme-color-global;
}

.help-button {
  width: 32px;
  height: 32px;
  background-color: $button-bg-primary;
  .icon-style {
    color: #ffffff;
  }
}

.help-button:hover {
  background-color: $button-bg-primary-hover;
}

.help-button:focus {
  background-color: $button-bg-primary-focus;
}

.instruction-position-error {
  margin: 10px;

  .instruction-close-button {
    margin-bottom: 10px !important;
    border-radius: 0 !important;
    padding: 4px 8px !important;
  }
}

.instruction-popover {
  .ant-popover-inner {
    border-radius: 8px;
  }
}



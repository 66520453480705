$theme-color-global: var(--themeColorGlobal, #19A9CE);

.customer-no-auth {
  margin-left: auto;
  margin-right: auto;
  max-width: 630px;
  min-width: 630px;
  height: 592px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .ant-card-body {
    padding-left: 31px;
    padding-right: 30px;
    padding-top: 56px;
  }

  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
  }

  .ant-input {
    font-weight: 600;
    height: 42px;
    width: 269px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    font-weight: 600;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
  }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $theme-color-global;
    border-color: $theme-color-global;
    border-radius: 4px;

    &:hover, &:focus {
      background-color: $theme-color-global;
      border-color: $theme-color-global;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $theme-color-global;
  }
  .ant-checkbox-checked::after {
    border: 1px solid $theme-color-global;
  }

  .ant-checkbox-wrapper:hover {
    color: $theme-color-global;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    border-color: $theme-color-global;
  }
}

.center-img {
  margin-right: auto;
  height: 40px;
}

.register-rc {
  margin-top: 32px;
  margin-bottom: 32px;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
}

.checkbox-group-rc {
  color: #000000;
  font-weight: 600;
}

.terms-conditions-rc {
  text-transform: lowercase;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.form-button-rc {
    background-color: $theme-color-global !important; 
    border-radius: 8px;
    border: none;
    height: 42px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;

    &:hover, &:focus {
      background-color: $theme-color-global !important;
    }
}

.language-switch-rc {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}

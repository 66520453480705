@import 'assets/scss/_variables.scss';

.clear-all-button{
    height: 14px;
    width: 71px;
    color: $theme-color;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    cursor: pointer;
    border: none;
    background: none;
}

.tag-filter {
    box-sizing: border-box;
    height: 24px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    background-color: #FAFAFA;
    color: #595959;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    padding-top: 3px;
    letter-spacing: 0;
    line-height: 17px;
    .ant-tag-close-icon{
        font-size: 14px;
    }
  }

@import 'assets/scss/_variables.scss';

$theme-color-global: var(--themeColorGlobal, #19A9CE);

.login-form {
  .ant-form-item {
    margin-bottom: 10px;

    .default-input-field {
      height: 42px;
      width: 100%;
      margin-bottom: 10px;
      font-size: 16px;
      padding-left: 16px;

      .ant-input {
        padding-left: 0;
        height: 100%;
        font-weight: 500;
        font-size: 16px;
        color: $text-color-03 !important;
      }
    }

    .ant-input-prefix {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    .ant-input-prefix i {
      font-size: 16px;
    }
  }

  .login-form-button {
    background-color: $theme-color-global;
    border-radius: 8px;
    border: none;
    height: 42px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;

    &:hover, &:focus {
      background-color: $theme-color-global;
    }
  }
}

.login-card {
  max-width: 600px;
  width: 100%;
  max-height: 410px;

  border-radius: 8px;

  .ant-card-body {
    padding: 50px 50px 25px 50px;
    @media (max-width:300px) {
      padding: 15px 15px 5px 15px;
    }
  }

  .ant-alert-info{
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #A1D4FB;
    background-color: #E9F7FE;
    margin-bottom: 20px;
    .ant-alert-icon {
      color: #19A9CE;
      font-family: "Font Awesome 5 Pro";
      font-size: 16px;
      font-weight: 300;
    }
  }
  .ant-alert{
    color: #262626;
    font-family: Gilroy;
    font-size: 14px;
  }

  .login-card-logo{
    max-width: 240px;
    min-width: 30px;
    width: 100%;
    max-height: 40px;
    min-height: 20px;
    height: 100%;
  }
}

.logo {
  max-width: 100%;
  margin-bottom: 32px;
}

.please-login-text {
  color: $base-color-dark;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 16px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.reset-password-text {
  color: #8C8C8C;
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.forgot-password-text {
  font-size: 12px;
  color: $link-color;
  text-decoration: underline;
  font-weight: 500;
}

.locale-switch {
  padding: 0 10px;
  border-radius: 8px;
  background: transparent;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 24px;
  height: unset;
  margin-right: 10px;
  border-color: rgba(232,232,232,.2);

  &:hover, &:focus {
    color: #FFFFFF;
    background: transparent;
    font-weight: 600;
    border-color: $theme-color-global;
  }

  &.active {
    color: #FFFFFF;
    background: transparent;
    font-weight: 600;
    border-color: $theme-color-global;
  }
}

.footer-list {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 30px;
    color: #FFFFFF;
    opacity: 50%;
    font-weight: 500;

    @media (min-width: 900px) {
      font-size: 14px;
    }
    
    @media (max-width: 500px) {
      font-size: 10px;
    }
    
  }
}

.password-reset-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.cs-login-logo{
  width: 100%;
  max-width: 150px;
}

.login-help{
  cursor: pointer;
  border:  1px solid $theme-color-global;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  padding: 0 10px;
  height: 46px;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
}

.login-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 50px;
  gap: 20px;

  @media (max-width:300px) {
    padding: 20px;
  }
}

.login-form-phone-input {
  .ant-input-group.ant-input-group-compact{
    display: flex;
  }
  .ant-select{
    height: 42px;
  }

  .login-form .ant-form-item{
    margin-bottom: 0;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px 0 0 8px;
    height: 42px;
    display: flex;
    align-items: center;

  }

  .ant-input {
    height: 40px;

  }
  .ant-input-affix-wrapper{
    border-radius: 0 8px 8px 0;

  }

  .ant-input-group.ant-input-group-compact > *:last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input{
    width: 100%;
  }
}

.login-options-wrapper{
  display: flex;
  height: 35px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}
@import 'assets/scss/_variables.scss';


.learning-activity-upload {
  .ant-form-item-label>label::after {
    content: '';
  }

  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

  }

  .ant-upload-list>div {
    flex: 50%;
    overflow: hidden;
  }
}

.learning-activity-settings {
  i+i {
    margin-left: 10px;
  }

  .fal {
    cursor: pointer;
    font-size: 14px;
  }
}


.panel-radius {
  .ant-collapse-item {
    border-radius: 8px !important;
  }
}

.file-settings-wrapper {
  padding: 15px;

  .file-settings-checkbox-wrapper {
    .ant-checkbox-wrapper {
      margin-left: 0;
    }

    display: flex;
    flex-direction: column;
  }
}

.add-material-button {
  .fas {
    margin-right: 3px;
  }
}

.resource-select-group {
  display: flex;

  .radio-button-content {
    margin: 5px;
    width: 160px;
    height: 160px;
  }

  .radio-button-content {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}


.hide-file-upload-icon {
  .ant-upload-text-icon {
    display: none;
  }

  .hide-file-upload-icon {
    display: none;
  }

  .ant-progress-outer {
    display: none;
  }
}

.file-settings-style {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;

  .fa-solid {
    margin-right: 5px;
  }
}

.file-settings-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.file-settings-title::after {
  content: ":";
  margin: 0 8px 0 2px;
}

.file-settings {
  .ant-input-disabled {
    cursor: default;
    background-color: #F0F2F5;
  }
}

.pdf-pass-rate-input {

  display: flex;
  justify-content: space-between;

  .ant-form-item-label>label::after {
    content: '';
  }

  .ant-input-number {
    border-right: 0;
  }

  .ant-input-number-group-addon {
    border-radius: 0 8px 8px 0;
    background-color: #fff;
  }

  .number-input {
    width: 100px;
  }
}

.pdf-pass-rate-label {
  display: flex;
  justify-content: flex-end;

}

.pdf-pass-rate-label::after {
  content: ':';
  margin: 0 8px 0 2px
}


.new-version-button-container {
  margin-bottom: 24px;
}

.language-radio-button {
  width: 75px;
  height: 32px;

  .ant-radio-button {
    position: inherit;
  }
}

.activity-form-settings {
  display: flex;
  column-gap: 60px;
  flex-wrap: wrap;
}

.activity-settings-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .first-container {
    width: 150px;
  }

  .second-container {
    width: 175px;
  }

  .third-container {
    width: 240px;
  }
}

.material-modal-wrapper {
  .fa-solid {
    color: $theme-color;
  }

  .ant-modal-body {
    padding: 40px;
  }
}

.material-type-button {
  .fa-solid {
    padding: 0 5px;
  }
}

.activity-settings-wrapper {
  background-color: white;
  padding: 24px 26px;
  border-left: 1px solid #f0f0f0;

  border-radius: 0 8px 8px 8px;
}

.material-panel {
  margin-top: 15px;
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.material-card-wrapper {
  width: 400px;

  .card-title-wrapper {
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .card-title {
      font-weight: 700;

      .fa-solid {
        margin-right: 10px;
        color: $theme-color;
      }
    }

    .material-settings {
      cursor: pointer;

      .fa-solid {
        color: #9D9D9D;
      }
    }
  }

  .material-details {
    display: flex;
    height: 30px;
    align-items: center;

    .material-label {
      font-weight: 700;
      width: 40%;
    }
  }

  .material-details:not(:last-child) {
    border-bottom: 1px solid #E4E4E4;
  }

  .material-description-wrapper {
    .ant-input {
      height: 22px;

    }
  }

  .material-title {
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
  }
}

.tabs-wrapper {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
}

.material-buttons-wrapper {
  font-weight: 700;
  margin: 10px 0;

  .ant-space {
    margin-top: 15px;
  }
}

.empty-material-card {
  color: #D8D8D8;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.button-outlined {
  border-color: $theme-color;
  color: $theme-color;

  .fa-solid {
    color: $theme-color;
    padding: 0 5px;
  }

  .ant-modal-body {
    padding: 40px;
  }
}
@import 'assets/scss/_variables.scss';

.add_remove_activity.ant-modal {
  .ant-transfer-list {
    height: 250px;
    width: 48%;
  }
}

.campaign-activities-resources-container {
  border-radius: 8px;

  .ant-collapse-item {
    border: 0;
  }

  margin-bottom: 5%;

  .assignment-container {
    font-weight: 700;
  }
}

.add-resource-button {
  display: flex;
  margin-bottom: 10px;
  border: 2px solid $theme-color;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: $theme-color;

  &:hover {
    border-color: $theme-color;
    color: $theme-color;
  }

  .fal {
    padding-right: 5px;
  }
}

.delete-submitted-file {
  cursor: pointer;
  margin-left: 5px;
}

.resource-deadline {
  max-width: 200px;
  min-width: 120px;
}

.campaign-custom-material {
  .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-top: 0px;
  }
}

.campaign-activities {
  .ant-transfer-list {
    width: 50%;
  }
}

.activity-icon {
  .fa-regular,
  .fa-solid {
    color: $theme-color;
  }
}
.checkbox {
  display: block;
  margin-left: 8px;
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    margin-left: 50px;
  }
}

.permissions {
  display: block;
  word-break: break-all;
  box-sizing: border-box;
  margin-right: 200px;
  margin-top: 32px;
}


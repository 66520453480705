@import 'assets/scss/_variables.scss';

.event-properties-table {
  table {
    tbody {
      tr {
        border-bottom: none;
        td {
          border-bottom: none;
        }
        td:hover {
          background: none;
        }
      }
      tr:hover {
        background: none;
      }
    }
  }
}

.description-icon {
  cursor: pointer;
  color: $theme-color;
}

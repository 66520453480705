@import '~antd/dist/antd.css';
@import '../fonts/gilroy/stylesheet.css';
@import './variables';
@import './utils';
@import './mixins';

// Components
@import './components/alerts';
@import './components/buttons';
@import './components/inputs';
@import './components/links';
@import './components/menu';
@import './components/modals';
@import './components/cards';
@import './components/tabs';
@import './components/tags';
@import './components/switch';
@import './components/inputNumber';
@import './components/checkbox';
@import './components/radioGroup';
@import './components/pagination';
@import './components/steps';

body {
  margin: 0;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// TODO: when there will be select component bring this out
.ant-select .ant-select-selector {
  border-radius: 8px;
}



.fullscreen-spinner {
  width: 100vw; height: 100vh;

  @include flex-center;
}

.ant-table-cell svg { margin-left: 4px; }

// Experimental fix for scroll bar flicker on certain resolutions
.ant-table-expanded-row-fixed { width: 100% !important; }

.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-tag {
    border-radius: $tag-border-radius;

    font-size: $tag-font-size;
    font-weight: $tag-font-weight;

    &.ant-tag-gray {
        /* Don't put stuff like margin in here (globally) if you need tags
         to have margins somewhere add them there so that it doesn't mess up tags
         EVERYWHERE, override the look, not the positioning */

        border-color: #D9D9D9;
        color:  #F5F5F5;
        background-color: #A9A9A9 !important;
    }
}
.connection-settings-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    font-weight: 700;
    border-radius: 8px;
    border: 1px #F0F2F5 solid;
    background-color: #F0F2F5;
}

.copy-button {
    cursor: pointer;
}

.groups-wrapper {
    .ant-form-item-label > label::after{
        content: '';
    }

    .ant-input {
        width: 95%;
    }

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
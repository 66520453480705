.page-content {
  background: white;
  padding: 16px;
  border-radius: 8px;

  &.with-top-padding {
    padding-top: 50px;
    margin-bottom: 50px;
  }
}

@import 'assets/scss/_variables.scss';

.available-language {
  margin-right: 5px;
}

.content-images-container {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  flex-wrap: wrap;

  .content-images-view:nth-of-type(even){
    padding-left: 8px;
  }

  .content-images-view:nth-of-type(odd){
    padding-right: 8px;
  }

  .content-images-view {
    border-radius: 4px;
    margin-bottom: 16px;
  }
}

.content-button-style {
  font-weight: bold;
  font-size: 14px;
}

.content-column {
  border: 1px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $menu-active-bg-color;

  text-align: center;
  color: white;

  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .fal.fa-book {
    margin-right: 2px;
  }
}

.content-view-headers {
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.content-outlined-button-style {
  color: var(--themeColor, #19A9CE);
  border-color: var(--themeColor, #19A9CE);
  background: #fff;
  font-weight: bold;
}


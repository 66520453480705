@import 'assets/scss/_variables.scss';

.page-layout {
  margin: $header-height 16px 16px 16px;

  &.with-sticky-footer {
    margin-bottom: calc(#{$footer-height} + 16px);
  }

  &.without-page-header {
    margin-top: calc(#{$header-height} + 16px);
  }

  &.without-legt-margin {
    margin-left: 0;
  }
}

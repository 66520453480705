@import '../../assets/scss/variables';

$button-responsive-width: 576px;
$button-responsive-width-2: 1200px;
$responsive-width: 1000px;
$item-padding: 0.5rem;
$button-width: 150px;
$button-spacing: 8px;

.card > :global(.ant-card-body) { padding: 1rem; }

@media (min-width: $responsive-width) {
    .container {
        display: flex;
        align-items: center;
    }
}

// Hides the "title" elements for all but the first card in the activity list when in 'desktop' mode
@media (min-width: $responsive-width) {
    :global(.ant-space) > :global(.ant-space-item:not(:first-of-type)) > .card .list {
        grid-template-rows: auto;

        &ItemTitle { display: none; }
    }
}

.list {
    flex: 1;
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, 1fr);

    gap: 10px;

    margin: -$item-padding 0;

    @media (min-width: $responsive-width) {
        grid-template-rows: repeat(2, auto);
        grid-template-columns: 2fr;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;

        margin: -$item-padding;
    }

    @media (max-width: 335px) {
        flex: 1;
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: repeat(1, 1fr);
    }

    &Item {

        &Title,
        &Content {
            @media (min-width: $responsive-width) {
                &:nth-child(2){
                    min-width: 50px;
                    max-width: 250px;
                }
    
                &:nth-child(4){
                    min-width: 50px;
                    max-width: 120px;
                }
    
                &:nth-child(6){
                    min-width: 50px;
                    max-width: 180px;
                }
    
                &:nth-child(8){
                    min-width: 50px;
                    max-width: 125px;
                }
    
                &:nth-child(10){
                    min-width: 50px;
                    max-width: 125px;
                }
    
                &:nth-child(12){
                    min-width: 50px;
                    max-width: 125px;
                }
            }

            padding: $item-padding;
            border-bottom: 1px solid #E8E8E8;

            @media (max-width: 335px) {
                &:nth-child(odd) {
                    border-bottom: 0px solid #E8E8E8;
                }
            }

            @media (min-width: $responsive-width) { border-bottom: none; }

            @media (max-width: $responsive-width) { 
                &:last-of-type,
                &:nth-last-of-type(2) { border-bottom: none; }
            }

            &Title {
            :global(.fa-solid) {color: $theme-color; margin-right: 5px; }
            }
        }
        &Title {
            color: #262626;
            font-weight: 600;
            white-space: nowrap;
        }

        &Content {
            color: #595959;
            
            display: flex;
            align-items: center;
            &Title{
                
                margin-left:5px ;
            }

            :global(.ant-tag) { margin: 2px; }
        }
    }

    .material{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        :global(.fa-solid) { margin: 3px; color: #E0E0E0; }

        &Display{
            @media (min-width: $responsive-width) { display: none; }
        }
    }
}

.button {
    display: block;
    width: 100%;

    &:last-of-type {
        margin-top: $button-spacing;
        min-width: 160px;

        @media (min-width: $button-responsive-width) { margin-top: 0; }
        @media (min-width: $responsive-width) { margin-top: 10px; }
        @media (min-width: $button-responsive-width-2) { margin-top: 0; }
    }

    &Learning { grid-area: Learning-Button; }
    &Test { grid-area: Test-Button; }

    &Container {
        margin-top: $item-padding * 3;
        padding: $item-padding;

        @media (min-width: $button-responsive-width) {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'Learning-Button Test-Button';
            column-gap: $button-spacing;
        }

        @media (min-width: $responsive-width) {
            display: block;
            margin-top: 0;
            margin-left: $item-padding * 3;
        }

        @media (min-width: $button-responsive-width-2) {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'Learning-Button Test-Button';
            row-gap: $button-spacing;
            align-items: center;
        }
    }
}

.materialsWrapper {
    padding: 5px;
    margin-top: 10px;
    color: #000;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #E0E0E0;

    :global(.fa-solid) {
        margin: 3px;
        color: #E0E0E0;
    }

    @media (max-width: $responsive-width) {
        display: none;
    }
}
@import 'assets/scss/_variables.scss';

.fa-ellipsis-v {
  padding:$padding-for-fa-ellipses-v;
}

.selectedUsers{
  margin-top: 5px;
}

.sticky-footer-buttons{
  .ant-btn{
    border-radius: 8px;
    height: 32px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }
}


.user-name-style {
  color: #19A9CE;
  cursor: pointer;
}
@import 'assets/scss/_variables.scss';

.site-header {
  display: flex;
  position: fixed;
  z-index: 3;
  width: 100%;
  background: white;
  padding: 0;
  padding-right: 16px;
  box-shadow: 0 3px 5px -5px rgba(0, 0, 0, 0.045);

  .header-menu{
    margin-left: auto;
    flex-shrink: 0;
  }

  .header-search.ant-input-affix-wrapper {
    margin-left: calc(#{$sidebar-width});
    border: 0;

    &.collapsed {
      margin-left: calc(#{$collapsed-sidebar-width});
    }
  
    &-focused {
      box-shadow: none;
    }
  
    .ant-input {
      font-weight: 500;
      height: $header-height;
    }

    i {
      font-size: 16px;
      color: $text-color-03;
    }
  }
  
  .brand-logo {
    display: inline-flex;
    height: 64px;
    max-width: 200px;
    
    img {
      height: 100%;
      max-width: 100%;
      max-height: 64px;
      padding: 15px 30px 15px 15px;
    }
  }

  i.header-item {
    color: $text-color-03;
    padding: 10px;
    cursor: pointer
  }
}

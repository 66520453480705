@import '../../../assets/scss/variables';

$button-responsive-width: 576px;
$button-responsive-width-2: 1200px;
$responsive-width: 992px;
$item-padding: 0.5rem;
$button-width: 150px;
$button-spacing: 8px;

.student-responsive-view {
    margin-top: 80px;
    margin-left: 100px;
    margin-right: 100px;

    @media (max-width: 650px) {
        margin-left: 0;
        margin-right: 0;
    }
}

.resource-card {
    @media (min-width: $responsive-width) {
        .settings-container {
            display: flex;
            align-items: center;
            justify-content: space-between
        }
    }
}

.list {
    flex: 1;
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, 1fr);

    margin: -$item-padding 0;

    @media (min-width: $responsive-width) {
        grid-template-rows: repeat(2, auto);
        grid-template-columns: 2fr;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;

        margin: -$item-padding;
    }

    &Item {

        &Title,
        &Content {
            padding: $item-padding;
            border-bottom: 1px solid #E8E8E8;

            @media (min-width: $responsive-width) {
                border-bottom: none;
            }

            &:last-of-type,
            &:nth-last-of-type(2) {
                border-bottom: none;
            }
        }

        &Title {
            color: #262626;
            font-weight: 600;
        }

        &Content {
            color: #595959;

            :global(.ant-tag) {
                margin-right: 0;
            }
        }
    }
}

.assignment-card {
    background-color: white;
    border-radius: 8px;
    margin-top: 10px;

    .assignment-header {

        padding: 10px 18px;
        border-radius: 8px 8px 0 0;

        .fa-solid {
            color: white;
            margin-right: 5px;
        }

        color: white;

        height: 40px;
        background-color: $theme-color;
    }

    .status-bar {
        color: #000;

        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: 960px) {
            grid-template-rows: repeat(2, auto);
            grid-template-columns: 2fr;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
        }

        .fa-solid {
            color: $theme-color;
            margin-right: 5px;

        }

        .activity-deadline,
        .activity-status {
            background-color: #F9F9F9;
            padding: 10px 18px;
        }

        .activity-status {
            @media (max-width: 960px) {
                padding-left: 40px;
            }
        }

        .status-bar-value {
            padding-right: 10px;
        }

        &:last-child {
            border-bottom: 1px solid #E0E0E0;
        }
    }

    .assignment-form {
        padding: 0 40px 15px 40px;
        margin-bottom: 10px;

        .fa-solid {
            color: $theme-color;
        }
    }

    .resource-headers {
        color: #000;
        font-weight: 700;
        margin: 20px 0;
    }

    .resource-container {
        margin-bottom: 50px;
    }

    .assignment-upload-button {
        margin-left: -8px;
    }
}

.file-resource-card {
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (min-width: 645px) {
        .ant-card-body {
            display: flex;
            justify-content: space-between;

        }
    }

    .resource-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20rem;
    }

    .ant-card-body {
        padding: 10px;
    }

    .ant-card-body::before {
        content: none;
    }

    .ant-card-body::after {
        content: none;
    }

    &:hover {
        background-color: #F9F9F9;
    }
}

.submitted-file {
    height: 22px;
    border-radius: 8px;
    margin: 1px;
    padding-left: 8px;

    .submitted-file-title {
        overflow: hidden;
        display: flex;
        align-items: center;

    }

    .settings-container {
        @media (max-width: 660px) {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-start;
        }

        display: flex;
        align-items: center;

        .admin-file-date {
            padding-right: 10px;
        }

        .admin-file-download {
            cursor: pointer;

            .anticon {
                padding-right: 5px;
            }
        }
    }

    @media (min-width: 745px) {
        display: flex;
        justify-content: space-between;
    }

    width: 100%;
}

.submitted-file:hover {
    background-color: #F9F9F9;

}

.rating-form {
    .rating-settings {
        font-weight: 600;
    }

    .ant-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ant-form-item {
        margin-bottom: 0;
        height: 50px;
    }

    .evaluation-settings {
        padding-left: 5px;
        padding-right: 5px;
        height: 50px;
        display: grid;
        grid-template-columns: 4fr 10fr;
        align-items: center;

        &:nth-child(odd) {
            background-color: #F9F9F9
        }
    }

    .passed-color {
        background-color: #F8FEEF !important;
    }

    .failed-color {
        background-color: #FFEFEFD9 !important;
    }

    .comment-height {
        height: 100%;
        min-height: 50px;
    }

    .comment-height-admin {
        height: 160px;

        textarea {
            margin-top: -45px;
            resize: none;

            height: 140px;
        }
    }

    .ant-form-item-label>label {
        padding-left: 5px;
        display: flex;

    }
}



.submit-student-assignment {
    width: 220px;
    font-weight: 700;
}
@import '../../../assets/scss/variables';

$button-responsive-width: 576px;
$button-responsive-width-2: 1285px;
$responsive-width: 1190px;
$item-padding: 1rem;
$button-width: 150px;
$button-spacing: 8px;


.ActivityCard {
    background-color: #FFFFFF;
    @media (max-width: $responsive-width) {
        padding: 0 0 $item-padding 0;
    }

    border-radius: 8px;

    &Header {
        border-radius: 8px 8px 0 0;
        height: 40px;
        background-color: $theme-color;
        padding: 10px;
        color: #fff;

        &Icon {
            margin-right: 10px;
        }
    }

    .Activity {
        &Wrapper {
            @media (min-width: $responsive-width) {
                display: flex;
            }
        }

        padding: 0 $item-padding 0 25px;

        flex: 1;
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: repeat(2, 1fr);

        @media (min-width: $responsive-width) {
            grid-template-rows: repeat(2, auto);
            grid-template-columns: 2fr;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
        }

        &Item {

            &Label,
            &Content {
                padding: 12px;
                border-bottom: 1px solid #E8E8E8;

                @media (min-width: $responsive-width) {
                    border-bottom: none;
                }

                &:nth-last-of-type(1) {
                    border-bottom: none;
                }
            }

            &Label {
                color: #262626;
                font-weight: 600;
                white-space: nowrap;
                padding: 12px;
            }

            &Content {
                color: #595959;

                :global(.ant-tag) {
                    margin-right: 0;
                }
            }

            &Content {}
        }
    }
}


.student-campaign-data {
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0,21,41,0.08);
    .ant-btn.primary-button{
        margin-top: 29px;
    } 
    .h1 {
        height: 24px;
        width: 252px;
        color: #262626;
        font-family: Gilroy;
        font-size: 24px;
        font-weight: 600;
    }
    .h2 {
        height: 20px;
        width: 541px;
        color: #595959;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 500;
    }
    .h3 {
        height: 20px;
        width: 124px;
        color: #262626;
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 500;
    }
    .student-campaign-tag {
        height: 22px;
        color: #1890FF;
        font-family: Gilroy;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        margin-top: 6px;
        margin-left: 10px;
        box-sizing: border-box;
        border: 1px solid #A1D4FB;
        border-radius: 8px;
        background-color: #E9F7FE;
    }
    .total-campaign-info {
        margin-right: 16px;
        font-family: Gilroy;
        font-size: 14px;
        font-weight: bold;
    }
}


.student-layout{
    margin: 20px 100px;

    @media (max-width: 550px) {
        margin: 20px 50px;
    
    }
    @media (max-width: 515px) {
        margin: 20px 15px;
        margin-top: 64px;
    }
}
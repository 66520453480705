@import 'assets/scss/_variables.scss';

.rowC{
    display:flex;
    flex-direction:row;
}

.language-link-blue {
    color: $theme-color;
    font-weight: 600;
    margin-right: 5px;
    cursor: pointer;
}

.ant-btn {
    border-radius: 8px;
}

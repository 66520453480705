.scenarios-modal{
  .ant-modal-content{
    border-radius: 8px 8px 8px 8px ;
  }
  .ant-modal-header{
    border-radius: 8px 8px 0 0 ;
  }

  .email-warning{
    margin-bottom: 24px;
  }
}

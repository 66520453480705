.page-under-construction-container {
  display: flex; 
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 42px;
    margin-bottom: 28px;
  }

  i {
    font-size: 104px;
  }
}

.spinner-wrapper.opaque {
  background: #fff;

  .ant-spin-blur {
    opacity: 0;
  }
}

.spinner-wrapper.opaqueH {
  background: #fff;

  .ant-spin-blur {
    opacity: 0.5;
  }
}

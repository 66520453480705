.ant-checkbox-wrapper {
    .ant-checkbox-wrapper .ant-checkbox-checked {
      background-color: $theme-color;
    }
    .ant-checkbox-wrapper .ant-checkbox-input:focus {
      border-color: $theme-color;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $theme-color;
      border-color: $theme-color;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: $theme-color;
      border-color: $theme-color;
    }

    &:hover {
      color: $theme-color;
      .ant-checkbox .ant-checkbox-inner {
        border-color: $theme-color;
      }
      .ant-checkbox::after {
        color: $theme-color;
        border-color: $theme-color;
      }
    }
  }
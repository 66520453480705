.clear-all-button {
  outline: 0;
  white-space: nowrap;
}

.filter-tag-container {
  .user-filter-bar-naming {
    color: black;
    font-size: 14px;
    font-weight: bold;
  }

  .filter-tags {
    display: flex;
    flex-wrap: wrap;
  }

  .customer-filter-tag {
    padding-right: 4px;
    padding-left: 4px;
    margin-right: 5px;
    margin-bottom: 3px;
    margin-top: 3px;
    box-sizing: border-box;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    background-color: #FAFAFA;
    color: #595959;
    font-weight: 500;
  }
}

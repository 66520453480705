@import 'assets/scss/_variables.scss';

.table-search-field {
    width: 150px;
    float: right;
    border-radius: 8px;
    
    .ant-input {
        border-radius: 8px;
    }
}

.fa-ellipsis-v{
  padding:$padding-for-fa-ellipses-v;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  line-height: 28px;
}

.ant-tag-green {
  color: #52C419;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;

  box-sizing: border-box;
  border: 1px solid #C3E999;
  border-radius: 8px;
  background-color: #F8FEEF;
}

.ant-tag-red {
  color: #F5222D;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;

  box-sizing: border-box;
  border: 1px solid #EFA7A1;
  border-radius: 8px;
  background-color: #FCF1F0;
}

.ant-tag-gold {
  color: orange;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;

  box-sizing: border-box;
  border: 1px solid gold;
  border-radius: 8px;
  background-color: rgba(255, 223, 0, .1)
}

.history-sender{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
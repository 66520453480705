@import 'assets/scss/_variables.scss';

.campaign-header-row-of-data {
    .ant-progress-inner {
        margin-bottom: 13px;
    }

    font-size: 14px;
    font-weight: 600;

    .fa-check-square {
        color: $link-color;
    }

    .fa-clock {
        color: $link-color;
    }

}

.signature-style {

    font-weight: 600;

    .fal {
        padding-right: 3px;
    }
}

.sign-red {
    .fa-user-edit {
        color: #E87673;
        font-weight: 600;
    }

    color: #E03C39;

}

.sign-green {
    .fa-user-edit {
        color: #74C13F;
        font-weight: 600;
    }

    color: #74C13F
}

.sign-button {
    cursor: pointer;
}

.signature-modal {
    .fa-user-edit {
        color: $link-color;
    }
}

.activity-list-back i.fal.fa-arrow-left {
    margin-top: 10px;
    color: $link-color;
}

.ant-table table .fa-check {
    color: $link-color;
}

.learing-status-NOT_STARTED {
    box-sizing: border-box;
    height: 32px;
    width: 159px;
    border: 2px solid $theme-color;
    border-radius: $button-border-radius;
    background-color: $button-color-primary;
    color: $link-color;
    font-size: 14px;
    font-weight: bold;

    &:hover {
        background-color: $button-color-primary;
        border: 2px solid $theme-color;
        color: $link-color;
    }
}

.learing-status-COMPLETED {
    border-radius: $button-border-radius;
    height: 32px;
    width: 159px;
    background-color: $theme-color;
    font-size: 14px;
    font-weight: bold;
    color: $button-color-primary;
}

.learing-status-IN_PROGRESS {
    border-radius: $button-border-radius;
    height: 32px;
    width: 159px;
    background-color: $theme-color;
    font-size: 14px;
    font-weight: bold;
    color: $button-color-primary;
}

.learing-status-EXEMPTED {
    border-radius: $button-border-radius;
    height: 32px;
    width: 159px;
    background-color: $theme-color;
    font-size: 14px;
    font-weight: bold;
    color: $button-color-primary;
}

.test-status-NOT_STARTED {
    box-sizing: border-box;
    height: 32px;
    width: 159px;
    border: 2px solid $theme-color;
    border-radius: $button-border-radius;
    background-color: $button-color-primary;
    color: $link-color;
    font-size: 14px;
    font-weight: bold;

    &:hover {
        background-color: $button-color-primary;
        border: 2px solid $theme-color;
        color: $link-color;
    }
}

.test-status-COMPLETED {
    border-radius: $button-border-radius;
    height: 32px;
    width: 159px;
    background-color: $theme-color;
    font-size: 14px;
    font-weight: bold;
    color: $button-color-primary;
}

.test-status-IN_PROGRESS {
    border-radius: $button-border-radius;
    height: 32px;
    width: 159px;
    background-color: $theme-color;
    font-size: 14px;
    font-weight: bold;
    color: $button-color-primary;
}

.test-status-FAILED {
    border-radius: $button-border-radius;
    height: 32px;
    width: 159px;
    background-color: $theme-color;
    font-size: 14px;
    font-weight: bold;
    color: $button-color-primary;
}

.choose-language .ant-modal-close-x {
    display: none;
}

.activity-list-wrapper {
    margin: 20px 100px;
}

@media (max-width: 600px) {
    .activity-list-wrapper {
        margin: 20px 50px;
    }
}

@media (max-width: 500px) {
    .activity-list-wrapper {
        margin: 20px 15px;
    }
}


.files-wrapper {
    margin: 20px 0;
}

.activity-wrapper {
    margin: 80px 16px 16px 16px;
}

.activity-content {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    margin: auto;
    padding: 20px;
    max-width: 890px;

    @media (min-width: 800px) {
        max-width: 890px;
    }

    @media (min-width: 1250px) {
        max-width: 895px;
    }

    @media (min-width: 2000px) {
        max-width: 1200px;
    }

    @media (min-width: 2500px) {
        max-width: 1900px;
    }
}
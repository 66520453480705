@import 'assets/scss/_variables.scss';

.table-search-gi {
  width: 150px;
  float: right;
  
}

.ant-popover-inner-content {
  padding: 0;

  .popover-item {
    font-size: 14px;
    color: $text-color-02;
    cursor: pointer;
    padding: 10px 40px 10px 20px;
  
    &:hover {
      background-color: $base-color-hover;
    }
   
    
  }
}

.fa-ellipsis-v{
  padding:$padding-for-fa-ellipses-v;
}

.ant-btn-link {
  color: $theme-color;
  transition: color .3s;

  &:hover { color: $theme-color; }
}

.license-toolbar-container {

  .valid-license-switch{
    padding-right: 10px;
    padding-top: 5px;
    min-width: 275px;
    float: right;

  }
    display: flex;
    justify-content: space-between;
    .license-button{

    margin-right: 10px ;
    margin-bottom: 10px ;
  }
}
.custom-filter-modal{
  .ant-modal-content {
    border-radius: 16px;  
  } 
  .ant-modal-header {
    border-radius: 16px 10px 0 0;
  }
}



.input-custom-filter{
    margin-right: -300px;
}
.flexRow {
  display: flex;
  align-items: flex-end;
  width: 100%;

  &Right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  /* If gap for flexbox was more widely supported this wouldn't be necessary, unfortunately, Safari doesn't support it */
  &Spacer {
    flex-shrink: 0;
    width: 8px;
    height: 1px;
  }
}

.flexRowEnd {
    display: flex;
    align-items: flex-end;
    width: 100%;

    &Right { margin-left: auto; }

    /* If gap for flexbox was more widely supported this wouldn't be necessary, unfortunately, Safari doesn't support it */
    &Spacer {
        flex-shrink: 0;
        width: 8px; height: 1px;
    }
}


.flexRowAlignCenter {
  display: flex;
  align-items: center;
  width: 100%;

  &Right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &Spacer {
    flex-shrink: 0;
    width: 8px;
    height: 1px;
  }
}
.campaign-settings-on-strictly {
    .ant-tabs-nav {
        display: none;
    }
    position: relative;
    top: -60px;
}
.campaign-settings-on-dynamic{
    position: relative;
    top: -80px;
}

.custom-campaign-type{
    color: #000001 !important;
    cursor: default !important;
}

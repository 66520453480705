.assignment-title {
    font-size: 16px;
    font-weight: 600;
}

.assignment-file {
    margin-left: 20px;
    font-weight: 500;
    margin: 5px;

    cursor: pointer;
}

.quick-progress-view {
    .ant-progress-inner {
        width: 95%;
    }

    .ant-progress-outer {
        border-radius: 8px;
        display: flex;
        padding: 2px;
        justify-content: center;
        border: #1890FF solid 2px;
    }
}

.quick-progress-view-hover {
    cursor: pointer;

    .ant-progress-inner {
        width: 95%;
    }

    .ant-progress-outer {
        border-radius: 8px;
    }

    .ant-progress-outer:hover {
        display: flex;
        padding: 2px;
        justify-content: center;
        border: #1890FF solid 2px;
    }
}